import {
  Component,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
  Input,
  Renderer2,
  AfterViewInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener,
  Optional,
  signal,
  computed,
} from "@angular/core";
import {
  ActivityIndicatorService,
  ApplicationService,
  ConferenceService,
  ConferenceStore,
  LocalMediaService,
  MediaRulesService,
  SketchService,
  StreamState,
  debugError,
} from "../../services";
import { tileSlideIn } from "../../core-ui.animations";
import { NotificationService } from "../../services/notification.service";
import { CobrowseService } from "../../services/cobrowse.service";
import {
  COBROWSER_PERMISSION,
  FilterType,
  IFilteredNodes,
  IReceiverEventMap,
} from "@auvious/cobrowser";
import {
  AgentParam,
  IArea,
  ICobrowseReceiverEventHandlers,
  IInteraction,
  ITile,
} from "../../models";
import { Subscription, filter } from "rxjs";
import {
  TileTypeEnum,
  LayoutEnum,
  EndpointTypeEnum,
  UserCapabilityEnum,
  CobrowseContextEnum,
  KEY_COBROWSE_CONTEXT,
  KEY_COBROWSE_SESSION_ID,
  AgentFeatureEnum,
} from "../../core-ui.enums";
import { RoomComponent } from "../room/room.component";
import { SketchAreaComponent } from "../sketch/sketch-area/sketch-area.component";
import { CobrowseFrameComponent } from "../cobrowse-frame/cobrowse-frame.component";
import { PointerComponent } from "../pointer/pointer.component";
import { IEndpointMetadata } from "../../models/IEndpointState";
import { TranslateService } from "@ngx-translate/core";
import { IEndpoint, IStream, StreamTypes } from "@auvious/rtc";
import { NgZone } from "@angular/core";
import { AppConfigService } from "../../services/app.config.service";
import { sessionStore } from "@auvious/utils";
import { IntegrationService } from "../../../app/services";

@Component({
  selector: "app-cobrowse-session",
  templateUrl: "./cobrowse-session.component.html",
  styleUrls: ["./cobrowse-session.component.scss"],
  animations: [tileSlideIn],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CobrowseSessionComponent
  implements OnInit, AfterViewInit, OnDestroy, ITile
{
  @Input() interaction: IInteraction;

  @Output() destroyed = new EventEmitter<CobrowseSessionComponent>();
  @Output() ready = new EventEmitter<CobrowseSessionComponent>();
  @Output() sketchReady = new EventEmitter<string>();

  @ViewChild("sketch") sketchRef: SketchAreaComponent;
  @ViewChild("cobrowseView") cobrowseRef: CobrowseFrameComponent;
  @ViewChild("pointer") pointerRef: PointerComponent;
  @ViewChild("tile") tileRef: ElementRef<HTMLDivElement>;

  handlers: ICobrowseReceiverEventHandlers;

  isViewGranted = signal(false);
  isControlGranted = signal(false);
  isRequestingControl = signal(false);
  isRequestingDisplayCapture = signal(false);
  isCapturingRemoteDisplay = signal(false);
  isCapturingLocalDisplay = signal(false);
  isConfirmOpen = signal(false);
  isFullWidth = signal(false);
  isCustomerSick = signal(false);
  isEndedByMe = signal(false);
  isFrameReady = signal(false);
  isStandalone = signal(false);
  isPublishingLocalDisplay = signal(false);
  isRequestingLocalDisplay = signal(false);
  isRecordingTracked = false;

  frameSize: { container: IArea; sketch: IArea; frame: IArea };
  pendingScrollPosition: Partial<IReceiverEventMap["mousemove"]>;
  customerName: string = this.translate.instant("Customer");
  customerEndpoint: IEndpoint;
  reconnectTimeout;
  TIMEOUT_MILISECONDS = 10000; // 20 seconds;

  pointer: Partial<IReceiverEventMap["mousemove"]> = {
    left: 0,
    top: 0,
  };

  remoteFrameSize = signal<IArea>({
    width: 0,
    height: 0,
  });

  frameScrollPosition: Partial<IReceiverEventMap["mousemove"]> = {
    left: 0,
    top: 0,
  };

  viewportScrollPosition: Partial<IReceiverEventMap["mousemove"]> = {
    left: 0,
    top: 0,
  };

  containerPadding = 60;
  resizeRatio = 1;
  mouseRatio = 1;

  subscription: Subscription = new Subscription();

  sketchId: string;
  sketchActive = false;

  layoutType: LayoutEnum;

  screenStream = signal<IStream>(undefined);
  screenStreamState: StreamState = this.store.screenStream;

  iconMode = signal("light");

  constructor(
    private activityService: ActivityIndicatorService,
    private notification: NotificationService,
    private cobrowseService: CobrowseService,
    private conferenceService: ConferenceService,
    private cd: ChangeDetectorRef,
    private host: ElementRef<HTMLDivElement>,
    private sketchService: SketchService,
    private renderer: Renderer2,
    private translate: TranslateService,
    private zone: NgZone,
    private config: AppConfigService,
    private local: LocalMediaService,
    private mediaRules: MediaRulesService,
    private store: ConferenceStore,
    private integration: IntegrationService,
    @Optional() private room?: RoomComponent
  ) {
    this.handlers = {
      viewAccepted: this.onViewAccepted.bind(this),
      controlAccepted: this.onControlAccepted.bind(this),
      controlRevoked: this.onControlRevoked.bind(this),
      filtered: this.onFiltered.bind(this),
      terminated: this.onTerminated.bind(this),
      mousemove: this.onMouseMove.bind(this),
      accessDenied: this.onAccessDenied.bind(this),
      resize: this.onRemoteWindowResized.bind(this),
      customerGotSick: this.onCustomerGotSick.bind(this),
      customerRecovered: this.onCustomerRecovered.bind(this),
      customerLeft: this.onCustomerLeft.bind(this),
      displayCaptureAccepted: this.onDisplayCaptureAccepted.bind(this),
      displayCaptureDenied: this.onDisplayCaptureDenied.bind(this),
      displayCaptureStreamAdded: this.onDisplayCaptureStreamAdded.bind(this),
      displayCaptureStreamRemoved:
        this.onDisplayCaptureStreamRemoved.bind(this),
      recordingFailed: () => {
        // TODO: show the user cobrowser recording failure
        this.notification.error("Oups!", {
          body: "Co-browse recording could not to start.",
        });
      },
    };

    this.isStandalone.set(!this.room);
    this.iconMode.set(!this.room ? undefined : "light");
  }

  @HostListener("window:beforeunload", ["$event"])
  beforeunload($event) {
    if (!this.isStandalone()) {
      return;
    }
    return ($event.returnValue = true);
  }

  ngOnInit() {
    this.subscription.add(
      this.sketchService.toolSelected$.subscribe((tool) => {
        const metadata = this.sketchService.getConferenceMetadata();
        metadata.setActiveTool(this.sketchId, tool);
        this.sketchService.setConferenceMetadata(metadata);

        !this.isStandalone()
          ? this.conferenceService.setConferenceMetadata(metadata)
          : this.cobrowseService.setConferenceMetadata(metadata);

        this.sketchService.notifyToolChanged(
          this.cobrowseService.target,
          this.sketchId,
          tool,
          null
        );
      })
    );

    this.subscription.add(
      this.store.updated$.subscribe(() => {
        if (this.screenStreamState !== this.store.screenStream) {
          this.screenStreamState = this.store.screenStream;
          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.local.localStreamReady$
        .pipe(
          filter((s) => s.type === StreamTypes.SCREEN && this.isStandalone())
        )
        .subscribe((s) => {
          // this fires for local stream
          this.trackDisplayCaptureRecordingStarted();
          this.isRequestingLocalDisplay.set(false);
          this.isPublishingLocalDisplay.set(true);
          this.isCapturingLocalDisplay.set(true);
          this.screenStream.set(s);
          this.cobrowseService.publish(s);
        })
    );
    this.subscription.add(
      this.local.streamRemoved$
        .pipe(
          filter((s) => s.type === StreamTypes.SCREEN && this.isStandalone())
        )
        .subscribe((s) => {
          this.isCapturingLocalDisplay.set(false);
          this.isPublishingLocalDisplay.set(false);
          this.screenStream.set(undefined);
          this.cobrowseService.unpublish(s);
        })
    );
    this.subscription.add(
      this.cobrowseService.localStreamPublished$
        .pipe(
          filter((s) => s.type === StreamTypes.SCREEN && this.isStandalone())
        )
        .subscribe((s) => {
          this.isPublishingLocalDisplay.set(false);
          this.local.replaceStream(s);
        })
    );
  }

  async ngAfterViewInit() {
    await this.cobrowseService.start(
      this.cobrowseRef.iFrame,
      this.handlers,
      this.interaction,
      !this.isStandalone()
        ? CobrowseContextEnum.CONFERENCE
        : CobrowseContextEnum.STANDALONE
    );

    // reconnect to existing session, if any
    const existingSession = sessionStore.getItem(KEY_COBROWSE_SESSION_ID);

    if (existingSession) {
      try {
        await this.cobrowseService.reconnect(existingSession);
        this.listenforRejoin();
      } catch (ex) {
        // if reconnect failed, start a new cobrowse session,
        // the remote participant is already joined and waits for an invitation
        this.start();
      }
    } else {
      this.start();
    }

    this.ready.emit(this);
  }

  private clearReconnectTimeout() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = null;
    }
  }

  private requestFirstPermission() {
    switch (this.cobrowseService.getRemoteCobrowseParticipantMetadata().type) {
      case EndpointTypeEnum.coBrowse:
        this.cobrowseService.requestPermission(COBROWSER_PERMISSION.VIEW);
        // track recording starts on view accepted
        break;
      case EndpointTypeEnum.displayCapture:
        this.cobrowseService.requestDisplayCapture();
        // track recording starts on published stream
        break;
    }
  }

  private start() {
    if (this.cobrowseService.isRemoteParticipantConnected) {
      this.requestFirstPermission();
    } else {
      this.reconnectTimeout = setTimeout(() => {
        // nobody joined, end the session
        this.end(true);
        this.notification.info("Co-browse session timed out", {
          body: "The session will now terminate.",
        });
      }, this.TIMEOUT_MILISECONDS);
    }
    // listen for new participant
    this.subscription.add(
      this.cobrowseService.participantJoined$.subscribe(() => {
        this.clearReconnectTimeout();
        this.requestFirstPermission();
      })
    );

    //
    this.listenforRejoin();
  }

  private listenforRejoin() {
    this.subscription.add(
      this.cobrowseService.participantRejoined$
        .pipe(
          filter(
            (_) =>
              this.isRequestingControl() || this.isRequestingDisplayCapture()
          )
        )
        .subscribe(() => {
          this.clearReconnectTimeout();
          this.isRequestingControl.set(false);
          this.isRequestingDisplayCapture.set(false);
        })
    );
  }

  ngOnDestroy() {
    this.activityService.loading(false);
    this.subscription.unsubscribe();
  }

  /** view actions  */

  async toggleLocalDisplayCapture() {
    if (this.isLocalDisplayCaptureActive()) {
      this.local.closeScreenStream();
    } else {
      this.mediaRules.dismissPendingConfirmations();
      this.isRequestingLocalDisplay.set(true);
      try {
        await this.local.shareScreen();
      } catch (ex) {}
      this.isRequestingLocalDisplay.set(false);
    }
  }

  async end(failedToReconnect = false) {
    this.isConfirmOpen.set(false);

    if (this.isLocalDisplayCaptureActive()) {
      this.local.closeScreenStream();
    }

    // terminate cobrowse, will send a terminate request that sketch will get to clean up.
    this.cobrowseService.terminate(failedToReconnect);

    try {
      this.sketchService.terminate(this.sketchId);
    } catch (ex) {
      debugError(ex);
    }
    this.activityService.loading(true, "Ending co-browse...");
  }

  expand() {
    this.isFullWidth.set(this.cobrowseRef.expand());
  }

  requestControl() {
    if (!this.isRequestingControl() && !this.isControlGranted()) {
      this.isRequestingControl.set(true);
      this.cobrowseService.requestPermission(COBROWSER_PERMISSION.CONTROL);
    }
  }

  toggleDisplayCapture() {
    if (this.isRequestingDisplayCapture()) {
      return;
    }
    this.isRequestingDisplayCapture.set(true);

    this.isCapturingRemoteDisplay()
      ? this.cobrowseService.terminateDisplayCapture()
      : this.cobrowseService.requestDisplayCapture();
  }

  closeControl() {
    // there is no way now to stop controlling
  }

  trackDisplayCaptureRecordingStarted() {
    if (
      this.cobrowseService.getRemoteCobrowseParticipantMetadata().type ===
        EndpointTypeEnum.displayCapture &&
      !this.isRecordingTracked
    ) {
      this.cobrowseService.trackRecordingStarted();
      // the agent may send more than one requests
      this.isRecordingTracked = true;
    }
  }

  sketchConnected(connectionId: string) {
    this.sketchId = connectionId;
    this.sketchReady.emit(connectionId);

    if (!!this.pendingScrollPosition) {
      this.scrollChanged(this.pendingScrollPosition);
      this.pendingScrollPosition = null;
    }
  }

  sketchControlStateChanged(enabled: boolean) {
    this.sketchActive = enabled;
  }

  mouseRatioChanged(ratio) {
    this.mouseRatio = ratio;
  }

  containerRatioChanged(ratio) {
    this.resizeRatio = ratio;
  }

  viewSizeChanged(frameSize) {
    this.frameSize = frameSize;
    // todo: check if sketch frame is updated on expand/collapse
  }

  scrollChanged(position: Partial<IReceiverEventMap["mousemove"]>) {
    if (this.sketchId) {
      this.sketchService.scrollTo(position.top, position.left, this.sketchId);
    } else {
      this.pendingScrollPosition = position;
    }
  }

  mediaElementReady(element: HTMLVideoElement) {
    this.zone.runOutsideAngular(() => {
      element.addEventListener("canplay", (_) => {
        this.resize({ width: element.videoWidth, height: element.videoHeight });
      });
      element.addEventListener("resize", (_) => {
        this.resize({ width: element.videoWidth, height: element.videoHeight });
      });
      window.addEventListener("resize", (_) => {
        this.resize({ width: element.videoWidth, height: element.videoHeight });
      });
    });
  }

  resize(viewSize: IArea) {
    const containerSize = this.container.getBoundingClientRect();
    if (!containerSize || !this.tileRef) {
      return;
    }
    containerSize.width -= this.containerPadding;
    containerSize.height -= this.containerPadding;

    const resizeRatioPerWidth = containerSize.width / viewSize.width;
    const resizeRatioPerHeight = containerSize.height / viewSize.height;

    const fitWidthSize: IArea = {
      width: containerSize.width,
      height: resizeRatioPerWidth * viewSize.height,
    };

    const ratio =
      fitWidthSize.height > containerSize.height
        ? resizeRatioPerHeight
        : resizeRatioPerWidth;

    const resizeRatio = ratio < 1 ? ratio : 1;

    const container: IArea = {
      width: viewSize.width * resizeRatio,
      height: viewSize.height * resizeRatio,
    };

    this.renderer.setStyle(
      this.tileRef?.nativeElement,
      "width",
      `${container.width}px`
    );
    this.renderer.setStyle(
      this.tileRef?.nativeElement,
      "height",
      `${container.height}px`
    );
  }

  /** cobrowse event handlers */

  onViewAccepted() {
    this.activityService.showIconMessage("cobrowse", "Customer connected", 2);
    this.isViewGranted.set(true);
    this.isFrameReady.set(false);
    this.isRequestControlAvailable;

    let participant;
    if (!this.isStandalone()) {
      participant = Object.keys(this.conferenceService.getParticipants())
        .map((key) => this.conferenceService.getParticipants()[key])
        .find(
          (p) =>
            (p.metadata as IEndpointMetadata)?.type ===
            EndpointTypeEnum.coBrowse
        );
    } else {
      participant = this.cobrowseService.target;
    }
    if (participant) {
      this.customerName =
        (participant.metadata as IEndpointMetadata).name || this.customerName;
      this.customerEndpoint = participant;
    }

    sessionStore.setItem(
      KEY_COBROWSE_SESSION_ID,
      this.cobrowseService.sessionId
    );

    sessionStore.setItem(
      KEY_COBROWSE_CONTEXT,
      !this.isStandalone()
        ? CobrowseContextEnum.CONFERENCE
        : CobrowseContextEnum.STANDALONE
    );

    this.cd.detectChanges();
  }

  onControlAccepted() {
    this.isControlGranted.set(true);
    this.isRequestingControl.set(false);
    this.notification.success("Control permission granted.");
  }

  onControlRevoked() {
    this.isControlGranted.set(false);
    this.notification.info("Control permission revoked.");
  }

  onAccessDenied(permission: COBROWSER_PERMISSION) {
    switch (permission) {
      case COBROWSER_PERMISSION.VIEW:
        this.isViewGranted.set(false);
        this.activityService.loading(false);
        this.notification.info("View permission denied.");
        break;
      case COBROWSER_PERMISSION.CONTROL:
        this.isControlGranted.set(false);
        this.isRequestingControl.set(false);
        this.notification.info("Control permission denied.");
        break;
    }
  }

  onFiltered(e: IFilteredNodes) {
    e.nodes.forEach((node, index) => {
      if (e.filters[index] === FilterType.Display) {
        const mask = node as HTMLElement;
        mask.style.backgroundColor = "#333";
        mask.style.color = "white";
        mask.style.textAlign = "center";
        mask.style.minHeight = "30px";
        const imgStyle = "width: 20px;display:inline-block; opacity: 0.5";
        const imgSrc = new URL(
          "/core-ui/assets/images/hide-light.svg",
          location.origin
        );

        // add an image
        mask.innerHTML = `<img src="${imgSrc}" style="${imgStyle}">`;
        this.cd.detectChanges();
      }

      // 1. Create a component reference from the component
      // const componentRef = this.componentFactoryResolver
      //   .resolveComponentFactory(IconComponent)
      //   .create(this.injector);

      // componentRef.instance.name = 'hide';

      // this.renderer.setStyle(componentRef.instance, 'width', '30px');
      // this.renderer.setStyle(componentRef.instance, 'display', 'block');
      // this.renderer.setStyle(componentRef.instance, 'position', 'absolute');
      // this.renderer.setStyle(componentRef.instance, 'top', '10px');
      // this.renderer.setStyle(componentRef.instance, 'left', 'calc(50% - 10px)');

      // 2. Attach component to the appRef so that it's inside the ng component tree
      // this.appRef.attachView(componentRef.hostView);

      // 3. Get DOM element from component
      // const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      //   .rootNodes[0] as HTMLElement;

      // 4. append to filter
      // filter.appendChild(domElem);
    });
  }

  onMouseMove(e: Partial<IReceiverEventMap["mousemove"]>) {
    this.pointer = {
      left: e.left * this.mouseRatio,
      top: e.top * this.mouseRatio,
    };

    this.renderer.setStyle(
      this.pointerRef.nativeElement,
      "transform",
      `translate(${e.left * this.mouseRatio}px, ${e.top * this.mouseRatio}px)`
    );
  }

  onRemoteWindowResized(e: IReceiverEventMap["resize"]) {
    this.remoteFrameSize.set(e);
    this.isFrameReady.set(true);
  }

  onCustomerGotSick() {
    this.notification.warn(
      "Customer seems to be having some connectivity issues."
    );
  }

  onCustomerRecovered() {
    this.notification.success("Customer recovered");
  }

  onCustomerLeft() {
    this.isCustomerSick.set(false);
    if (
      (this.isCobrowseRequest && this.isViewGranted()) ||
      this.isDisplayCaptureRequest
    ) {
      this.notification.info("Customer left.");
    }
  }

  onTerminated() {
    if (this.isLocalDisplayCaptureActive()) {
      this.local.closeScreenStream();
    }
    if (
      (this.isCobrowseRequest && this.isViewGranted()) ||
      this.isDisplayCaptureRequest
    ) {
      this.notification.info("Session ended.");
    }
    sessionStore.removeItem(KEY_COBROWSE_SESSION_ID);
    sessionStore.removeItem(KEY_COBROWSE_CONTEXT);
    this.activityService.loading(false);
    this.destroyed.emit(this);
  }

  onDisplayCaptureAccepted() {
    this.isCapturingRemoteDisplay.set(true);
  }

  onDisplayCaptureDenied() {
    this.isRequestingDisplayCapture.set(false);
    this.isCapturingRemoteDisplay.set(false);
  }

  onDisplayCaptureStreamAdded(stream: IStream) {
    // this fires for remote stream
    this.trackDisplayCaptureRecordingStarted();
    this.isRequestingDisplayCapture.set(false);
    this.isCapturingRemoteDisplay.set(true);
    this.screenStream.set(stream);
  }

  onDisplayCaptureStreamRemoved(stream: IStream) {
    this.screenStream.set(undefined);
    this.isRequestingDisplayCapture.set(false);
    this.isCapturingRemoteDisplay.set(false);
  }

  setLayoutType(type: LayoutEnum) {
    this.layoutType = type;
  }

  /** view getters */

  get uuid() {
    return "cobrowse-session";
  }

  get type() {
    return TileTypeEnum.coBrowse;
  }

  get ratio() {
    return 16 / 9;
  }

  get container() {
    return this.host.nativeElement;
  }

  get isResizeDisabled() {
    return computed(() => {
      return this.resizeRatio === 1 || this.isCapturingRemoteDisplay();
    });
  }

  get isCobrowseActive() {
    return computed(() => {
      return this.isViewGranted() || this.isControlGranted();
    });
  }

  get isCobrowseControlDisabled() {
    return computed(() => {
      return !this.cobrowseService.isStarted || this.isCapturingRemoteDisplay();
    });
  }

  get isWaitingToAccept() {
    return computed(
      () =>
        (this.isCobrowseRequest && !this.isCobrowseFrameOpen()) ||
        (this.isDisplayCaptureRequest && !this.isCapturingRemoteDisplay())
    );
  }

  get isCobrowseFrameOpen() {
    return computed(() => {
      return this.isCobrowseActive() && this.remoteFrameSize().width > 0;
    });
  }

  get isCobrowseRequest() {
    return [
      this.cobrowseService.getRemoteCobrowseParticipantMetadata()?.type,
      this.cobrowseService.getRemoteEndpointRequestType(),
    ].includes(EndpointTypeEnum.coBrowse);
  }

  get isDisplayCaptureRequest() {
    return [
      this.cobrowseService.getRemoteCobrowseParticipantMetadata()?.type,
      this.cobrowseService.getRemoteEndpointRequestType(),
    ].includes(EndpointTypeEnum.displayCapture);
  }

  get isRemoteFrameSizeAvailable() {
    return computed(
      () =>
        this.remoteFrameSize().width > 0 && this.remoteFrameSize().height > 0
    );
  }

  get isRemoteDisplayCaptureAvailable() {
    return computed(() => {
      return (
        this.isStandalone() &&
        this.config.agentParamEnabled(
          AgentParam.COBROWSE_SCREEN_SHARE_ENABLED
        ) &&
        (
          this.cobrowseService.target?.metadata as IEndpointMetadata
        )?.capabilities?.includes(UserCapabilityEnum.displayCapture)
      );
    });
  }

  get isLocalDisplayCaptureAvailable() {
    return computed(() => {
      return (
        this.isStandalone() &&
        this.config.agentParamEnabled(
          AgentParam.COBROWSE_AGENT_SCREEN_SHARE_ENABLED
        ) &&
        this.integration.supportsAgentFeature(AgentFeatureEnum.displayCapture)
      );
    });
  }

  get isLocalDisplayCaptureActive() {
    return computed(() => {
      return (
        this.screenStream()?.originator?.endpoint ===
        this.cobrowseService.myself.endpoint
      );
    });
  }

  get isLocalDisplayCaptureLoading() {
    return computed(() => {
      return this.isRequestingLocalDisplay() || this.isPublishingLocalDisplay();
    });
  }

  get isRequestControlDisabled() {
    return computed(() => {
      return (
        this.isCobrowseControlDisabled() ||
        this.isRequestingDisplayCapture() ||
        this.isLocalDisplayCaptureLoading()
      );
    });
  }

  get isLocalDisplayCaptureDisabled() {
    return computed(() => {
      return (
        this.isRequestingControl() ||
        this.isLocalDisplayCaptureLoading() ||
        this.isRequestingDisplayCapture() ||
        this.isCapturingRemoteDisplay()
      );
    });
  }

  get isCaptureRemoteDisplayDisabled() {
    return computed(() => {
      return (
        this.isRequestingControl() ||
        this.isLocalDisplayCaptureLoading() ||
        this.isLocalDisplayCaptureActive()
      );
    });
  }

  get isRequestControlAvailable() {
    return computed(
      () =>
        this.config.agentParamEnabled(
          AgentParam.COBROWSE_CONTROL_PERMISSION_ENABLED
        ) && this.isViewGranted()
    );
  }

  get isResizeAvailable() {
    return computed(() => this.isViewGranted());
  }
}
